@import 'node_modules/sass-rem/_rem.scss';
@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
@import '@appkit4/styles/scss/_variables.scss';
@import 'sass-rem/_rem.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

.underline {
  text-decoration: none;
}
body {
  font: $typography-body;
  background-color: $color-background-default;
  color:$color-text-body;
}

h1 {
  margin: $spacing-7 0;
  font: $typography-heading-l;
}
h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin: 12px 0;
  font: $typography-heading-m;
}
h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 12px 0;
}
a {
  color: $color-text-body;
}
ul {
  list-style-type: none;
  padding-inline-start: 24px;
  & > li {
    padding: 8px 0;
  }
}
ol.alphabetical {
  list-style-type: lower-alpha;
  & > li {
    display: list-item;
    padding: 8px;
  }
  & > li:before {
    content: none;

  }
  & > li ol > li:before {
    content: none;

  }
}
ol.terms {
  list-style-type: none;
  counter-reset: item;
  padding-inline-start: 24px;
  & > li {
    display: table-row;
    counter-increment: item;
  }
  & > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding: 16px;
  }
  // & > li ol > li:before {
  //   content: counter(item) ". ";
  // }
}
.pointer {
  cursor: pointer;
}
.access-risk {
  color: #E0301E;
}
.ap-input-form {
  .ap-field {
    padding: 10px 0;
  }
  .ap-footer {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .ap-form-error {
    padding: 8;
    color: #C52A1A;
    margin: "8px 0";
  }
}
.ap-font-xlarge {
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
}
.ap-font-large {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.ap-overlay {
  background-color: #F3F3F3;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 400;
}
.ap-analysis-nav-wrapper {
  display: block;
  // padding-top: 8px;
  .ap-analysis-nav{
  }
}
.ap-panel {
  margin-bottom: 16px;
  padding-top: 16px;
}
.ap-indicator-green {
  width: 12px;
  height: 12px;
  background-color: #4EB523;
  display: inline-block;
  border-radius: 8px;
  margin-right: 8px;
}
.ap-process-span
{
  margin-right: 8px;
  width: 64px; 
  display: inline-block;
}
.ap-indicator-red {
  width: 12px;
  height: 12px;
  background-color: #E0301E;
  display: inline-block;
  border-radius: 8px; 
  margin-right: 8px;
}
.ap-trend-up {
  color: #E0301E;
}
.ap-trend-down {
  color: #4EB523;
}
.ap-option-item {
  height: auto;
}
.ap-badge-trend {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ap-badge-usercount {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 72px;
}
.ap-badge-process {
  // border-radius: 0 !important;
  // width: 72px;
  margin-right: 8px;
  // box-shadow: 0 0 0.3rem 0.1rem #aeaeae;
}
.ap-list-profile {
  background-color: transparent;
}
.ap-list-item {
  color: #2D2D2D;
}
.ap-tool-item {
  margin-right: 16px;
}
.ap-mandatory-asterisk {
  color: #D04A02;
}
.ap-navigation-wrapper {
  height: auto;
}
.ap-wrapper-matches {
  background-color: #F3F3F3;
}
.ap-wrapper-inline {
  padding: 8px;
}
.ap-recharts-tooltip {
  border-radius: $border-radius-2;
}
.list-transaction {
  //box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  border-radius: var(--border-radius-3);
}
.list-row-1 {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  //border-top-right-radius: var(--border-radius-3);
  &:nth-child(even) {
    // background-color: var(--color-background-selected);
    background-color: #F3F3F3;
  }
  &:hover:nth-child(odd) {
  background-color: #E8e8e8;
    // background-color: var(--color-background-selected);
  }
  &:hover:nth-child(even) {
    // background-color: var(--color-background-hover-selected);
    background-color: #E8E8E8;
  }
  &:last-child {
  }
  &:first-child {

  }
  b {
    font-weight: var(--font-weight-2);
    color: #474747;
  }
}
.list-row {
  background-color: #e5e5e5;
  border-bottom: 1px solid #f0f0f0;
  &:nth-child(even) {
    background-color: #EEEEEE;
  }
  &:hover:nth-child(odd) {
    background-color: #DCDCDC;
  }
  &:hover:nth-child(even) {
    background-color: #E0E0E0;
  }
  b {
    font-weight: var(--font-weight-2);
    color: #474747;
  }
}
// .list-header {
//   color: var(--color-text-heading);
//   background-color: #dedede;
//   font-weight: var(--font-weight-2);
//   letter-spacing: -0.025rem;
// }
.ap-item-count {
  padding: 8px;
  &::before {
    content: " (";
  }
  &::after {
    content: ")";
  }
}
.list-header {
  color: var(--color-text-heading);
  font-weight: var(--font-weight-2);
  letter-spacing: -0.025rem;
  padding: 4px 8px;
  border-bottom: 1px solid #dedede;
}
.open {
  border: 1px solid #dedede;
  border-radius: var(--border-radius-3);
}
.list-row-transaction-functions {
  padding: 8px;
  border-bottom: 1px solid #dedede;
}
.list-row-test {
  background-color: #ffffff;
}
.list-row-test:first-of-type {
  border: none;
}
.list-row-test:last-child {
  margin-bottom: 16px;
}
.list-row-test:nth-child(even) {
  background-color: var(--color-background-selected);
}

.list-filter {
  width: 256px;
  padding: 0;
}
.list-icon {
  color: #D04A02;
}
.list-lock {
  color: #2D2D2D;
}
.list-sortable {
  //cursor: pointer;
}
.list-item-count {
  margin: 8px;
}
.ap-percentagebar {
  width: 100%;
  display: inline-block;
  .ap-percentagebar-amount {
    border-right: 1px solid #f2f2f2;
    font-size: 12px;
    color: #2F2F2F;
    padding: 4px;
  }
}
@keyframes cookie-bar {
  from {
    bottom: -200px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes slide-left {
  from {
    left: -200px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
@keyframes slide-top {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
ap-navigation-sidebar {
  background-color: $color-background-default;
  width: fit-content;
}

.ap-user-process {
  // font: $typography-heading-m;
  padding: 24px;
  // padding-left: 96px;
  margin-top: 8px;
  // border-bottom: 2px solid #dedede; 
}
.ap-userdata-box {
  position: absolute;
  top: 624px;
  left: 0px;
  z-index: 1000;
  padding: 16px;
  opacity: 1;
  animation-name: slide-left;
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  //background: #EEEEEE;
  //box-shadow: $level-2;
  width: 312px;
}
.ap-userdata-float {
  position: fixed;
  top: 0px;
}
.ap-panel-statistics {
  min-height: 462px;
}
.ap-match-value {
  display: inline-block;
  &::after {
    content: "OR";
    margin: 4px;
    font-size: 12px;
  }
  &:last-child::after {
    content: none;
  }
}

.ap-function-description {
  &::after {
    content: " AND ";
    font-size: 14px;
  }
  &:last-child::after {
    content: none;
  }
}
.ap-cookie-bar {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: #2D2D2D;
  z-index: 1000;
  color: #f0f0f0;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.5em;
  animation-name: cookie-bar;
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  p {
    font-size: smaller;  
  }
  button {
    margin-top: $spacing-7;
  }
  a {
    color:#E0301E;
    margin-left: 8px;
  }
  a:hover {
    text-decoration: underline;
  }
}
.ap-grid-header {
  color: #2D2D2D;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.ap-accordion {
  .ap-accordion-toggle {
    padding: 8px 0;
  }
  .ap-accordion-content {
    padding: 0;
  }
}
.ap-toolbar-wrapper {
  margin: 8px 0;
}
.ap-accordion-open {
  border-left: 2px solid $color-background-tertiary;
}
.ap-userlist-info {
  margin-bottom: 24px;
}
.ap-navigation-wrapper .ap-navigation-footer {
  position: fixed;
  bottom: 0;
}
// .ap-table {
//   padding: 0px;
//   table {
//     border-collapse: collapse;
//     font-size: 0.85rem;
//     border-radius: $border-radius-1;
//     tbody {
//       tr {
//         // cursor: pointer;
//         td {
//         }
//       }
//     }
//   }
//   th .header-container {
//     margin-bottom: 0;
//   }
// }
.ap-drawer {
  z-index: 2000;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-underline {
  background-color: var(--color-background-selected);
}
.pagination-offset {
  width: 192px;
}

.ap-pattern-error-wrapper {
  background-image: url(./svg/svg-risk-management-fractional-1.svg);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ap-tag {
  margin-right: 8px;
  margin-top: 8px;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: $height-9;
  height: 100%;
  .login-body {
    min-width: rem(548px);
    h3 {
      font-weight: var(--font-weight-2);
      font-size: 1.25rem;
      line-height: 1.25rem;
      letter-spacing: -.01625rem;
      color: var(--color-text-light);
      margin-bottom: 0.875rem;
    }
    h1 {
      font-weight: var(--font-weight-1);
      font-size: 3rem;
      line-height: 3.625rem;
      letter-spacing: -.07rem;
      color: var(--color-text-heading);
    }
    button {
      width: 100%;
    }
    button:last-of-type {
      margin-top: 1rem;
    }
  }
  .login-footer {
    bottom: 0;
    position: fixed;
    z-index: 10;
  }
}
.login-logo {
  position: fixed;
  top: 40px;
  left: 40px;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  width: rem(116px);
  height: rem(89px);
  overflow: auto;
  background-image: url(./svg/logo.svg);
  background-size: cover;
  background-position: center;
}
.background-right {
  height: 100%;
  background-size: cover;
  background-position: right;
  background-repeat: repeat-y;
  background-image: url(./svg/svg-generic-1.svg);
}
// .ap-footer-link-group {
//   justify-content: center;
// }
.ap-modal-body {
  overflow: visible;
}
.ap-warning {
  background-color: $color-background-warning;
  border-radius: $border-radius-2;
}
.ap-error {
  padding: 8px;
  background-color: $color-background-danger;
  color: $color-text-secondary;
  margin: 8px 8px;
  border-radius: $border-radius-2;
}
.ap-business-process-test-header {
  background-color: $color-background-tertiary;
  color: #ffffff;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
  div {
  }
}
.ap-business-process-header {
  background-color: $color-background-primary;
  color: #ffffff;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}
.ap-users-header {
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}
.ap-list-item-draggable {
  display: flex;
  width: 100%;
  border-radius: var(--border-radius-3);
  padding: var(--spacing-4);
  align-items: center;

  .Appkit4-icon{
    font-size: 1rem;
    color: var(--color-background-tertiary);
    margin-right: var(--spacing-3);
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
  }
}
.ap-floater {
  top: 0;
  position: fixed;
  animation-name: slide-top;
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  box-shadow: $level-2;
  z-index: 1000;
}
.tabs-wrapper {
  padding-top: $spacing-5;
  margin-left: $spacing-4;
}
.accordion-wrapper {
  padding-bottom: $spacing-7;
}
.ap-font-small {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.tags-panel-border{
  background-color: #D9D9D9;
  height: rem(32px);
  width: rem(1px);
  margin-left:rem(8px);
  margin-right: rem(8px);
}
.ap-tag-werks {
  background-color: #EB8C00;
  color: #000000;
}
.ap-tag-vkorg {
  background-color: #FFB600;
  color: #000000;
}
.ap-panel-information {
  margin: 16px;
}
.ap-panel-graph {
  margin-top: 24px;
}
.ap-dropdown-custom-wrapper {
  // padding: var(--spacing-3);
  // height: auto;
}
.ap-btn-group {
  justify-content: inherit;
}
.ap-select-danger {
  color: #E0301E;
  font-weight: 500;
}
.ap-preferences-modal {
  .body {
    padding: 0;
  }
  .header {
    height: 1.75rem;
    margin: 0 0 var(--spacing-4);
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--border-radius-2);
    background-color: var(--color-background-hover);
    font-size: .875rem;
    line-height: 1.25rem;
    letter-spacing: -.025rem;
    color: var(--color-text-heading);
    width: 100%;
  }
}
.ap-legend {
  display: flex;
  justify-content: center;
  align-items: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  .ap-legend-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 12px;
    .ap-legend-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}

.ap-bar-label {
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  fill: #2D2D2D;
}