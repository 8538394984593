@import 'node_modules/sass-rem/_rem.scss';
@import '@appkit4/styles/scss/variables';

.ap-pattern-form {
  margin: 16px 0;
  .ap-pattern-form-title {
    font-weight: $font-weight-2;
    font-size: $typography-text-size-5;
    line-height: $typography-line-height-5;
    letter-spacing: $typography-letter-spacing-1;
    color: var(--color-text-heading);
  }
  
  .ap-pattern-form-required-indicator {
    position: relative;
    font-weight: $font-weight-1;
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    letter-spacing: $typography-letter-spacing-1;
    color: var(--color-text-light);
    margin-left: 32px;
    &::before {
        content: "";
        position: absolute;
        left: calc(-1 * var(--spacing-3));
        top: var(--spacing-2);
        width: rem(4px);
        height: rem(4px);
        border-radius: var(--border-radius-1);
        background-color: var(--color-text-error);
        transition: all .3s;
    }
  }
}

